import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  TextField,
  InputAdornment
} from '@mui/material';
import {
  Add as AddIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Engineering as EngineeringIcon,
  Assignment as AssignmentIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { supabase } from '../utils/supabaseClient';
import { generateSOW } from '../utils/pdfGenerator';
import Header from './Header';
import { SOW_TYPES, SOW_TYPE_LABELS } from '../constants/sowTypes';

function SOWList() {
  const navigate = useNavigate();
  const [sows, setSows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedSowId, setSelectedSowId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const { user } = useAuth();

  const fetchSOWs = async () => {
    try {
      setLoading(true);
      
      // First get the total count
      const { count } = await supabase
        .from('sows')
        .select('*', { count: 'exact', head: true });
      
      setTotalCount(count);

      // Then get the paginated data
      let query = supabase
        .from('sows')
        .select('*');

      // Add search filter if query exists
      if (searchQuery) {
        query = query.or(`client_name.ilike.%${searchQuery}%,prepared_by.ilike.%${searchQuery}%`);
      }

      const { data, error: fetchError } = await query
        .order('created_at', { ascending: false })
        .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1);

      if (fetchError) throw fetchError;
      setSows(data || []);
    } catch (error) {
      console.error('Error fetching SOWs:', error);
      setError('Failed to load SOWs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSOWs();
  }, [page, rowsPerPage, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = async (sow) => {
    try {
      await generateSOW(JSON.parse(sow.form_data), sow.sow_type);
    } catch (error) {
      console.error('Error downloading SOW:', error);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedSowId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const { error } = await supabase
        .from('sows')
        .delete()
        .match({ id: selectedSowId });

      if (error) throw error;
      
      fetchSOWs();
      setDeleteDialogOpen(false);
      setSelectedSowId(null);
    } catch (error) {
      console.error('Error deleting SOW:', error);
    }
  };

  const handleClone = async (sow) => {
    try {
      setLoading(true);
      const formData = JSON.parse(sow.form_data);
      
      // Increment version by 0.1
      const currentVersion = parseFloat(formData.documentInfo.version) || 1.0;
      formData.documentInfo.version = (currentVersion + 0.1).toFixed(1);

      const { data: newSow, error } = await supabase
        .from('sows')
        .insert([{
          form_data: JSON.stringify(formData),
          sow_type: sow.sow_type,
          client_name: formData.clientInfo.clientName || 'Untitled',
          prepared_by: formData.documentInfo.preparedBy || '',
          created_by: user?.id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;

      setNotification({
        open: true,
        message: 'SOW cloned successfully!',
        severity: 'success'
      });

      navigate(`/edit/${newSow.id}`);
    } catch (error) {
      console.error('Error cloning SOW:', error);
      setNotification({
        open: true,
        message: 'Error cloning SOW. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const renderTypeChip = (type) => {
    switch (type) {
      case SOW_TYPES.STAFF_AUG:
        return (
          <Chip
            icon={<EngineeringIcon />}
            label={SOW_TYPE_LABELS[SOW_TYPES.STAFF_AUG]}
            size="small"
            color="info"
            variant="outlined"
          />
        );
      case SOW_TYPES.PROJECT:
      default:
        return (
          <Chip
            icon={<AssignmentIcon />}
            label={SOW_TYPE_LABELS[SOW_TYPES.PROJECT]}
            size="small"
            color="primary"
            variant="outlined"
          />
        );
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      
      <Container maxWidth="lg" sx={{ py: 4, flexGrow: 1 }}>
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" component="h1" color="primary">
            Statement of Work Records
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/create')}
          >
            Create New SOW
          </Button>
        </Box>

        <Paper
          elevation={0}
          sx={{ 
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Box sx={{ p: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search by client name or prepared by..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                )
              }}
            />
          </Box>

          {error && (
            <Alert severity="error" sx={{ m: 2 }}>
              {error}
            </Alert>
          )}

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Prepared By</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : sows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                      <Typography color="text.secondary">
                        {searchQuery ? 'No matching SOWs found' : 'No SOW records found'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sows.map((sow) => {
                    const formData = JSON.parse(sow.form_data);
                    return (
                      <TableRow key={sow.id} hover>
                        <TableCell>{formData.clientInfo.clientName}</TableCell>
                        <TableCell>{renderTypeChip(sow.sow_type)}</TableCell>
                        <TableCell>{formData.documentInfo.version}</TableCell>
                        <TableCell>{formData.documentInfo.preparedBy}</TableCell>
                        <TableCell>
                          {format(new Date(sow.created_at), 'dd MMM yyyy')}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Download">
                            <IconButton onClick={() => handleDownload(sow)} size="small">
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Clone">
                            <IconButton 
                              onClick={() => handleClone(sow)}
                              size="small"
                              sx={{ ml: 1 }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton 
                              onClick={() => navigate(`/edit/${sow.id}`)}
                              size="small"
                              sx={{ ml: 1 }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton 
                              onClick={() => handleDeleteClick(sow.id)}
                              color="error"
                              size="small"
                              sx={{ ml: 1 }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* Delete Confirmation Dialog */}
        <Dialog 
          open={deleteDialogOpen} 
          onClose={() => setDeleteDialogOpen(false)}
          PaperProps={{
            elevation: 0,
            sx: { borderRadius: 2 }
          }}
        >
          <DialogTitle>Delete SOW</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this SOW? This action cannot be undone.
          </DialogContent>
          <DialogActions sx={{ p: 2.5, pt: 1.5 }}>
            <Button 
              onClick={() => setDeleteDialogOpen(false)}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleDeleteConfirm}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}


export default SOWList;
