import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, Paper } from '@mui/material';

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ align: [] }],
  ['clean']
];

const modules = {
  toolbar: TOOLBAR_OPTIONS,
  clipboard: {
    matchVisual: false
  }
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'indent',
  'align'
];

function RichTextEditor({ value, onChange, placeholder, minHeight = '200px' }) {
  return (
    <Box sx={{ 
      '& .ql-container': {
        fontSize: '1rem',
        fontFamily: 'inherit'
      },
      '& .ql-editor': {
        minHeight,
        fontFamily: 'inherit'
      }
    }}>
      <Paper
        variant="outlined"
        sx={{
          '& .ql-toolbar': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: '1px solid',
            borderColor: 'divider',
            px: 1.5
          },
          '& .ql-container': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none'
          }
        }}
      >
        <ReactQuill
          theme="snow"
          value={value || ''}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
        />
      </Paper>
    </Box>
  );
}

export default RichTextEditor;