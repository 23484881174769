import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent
} from '@mui/material';
import RichTextEditor from '../common/RichTextEditor';

function ProjectDetails({ data, onChange }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({
      ...data,
      [name]: value
    });
  };

  const handleDescriptionChange = (value) => {
    onChange({
      ...data,
      description: value
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Project Details
      </Typography>

      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="title"
                label="Project Title"
                value={data.title || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Project Description
              </Typography>
              <RichTextEditor
                value={data.description || ''}
                onChange={handleDescriptionChange}
                placeholder="Enter project description..."
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ProjectDetails;