import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent
} from '@mui/material';

function DocumentInfo({ data, onChange }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({
      ...data,
      [name]: value
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Document Information
      </Typography>

      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="preparedBy"
                label="Prepared By"
                value={data.preparedBy || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="version"
                label="Version"
                value={data.version || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="salesRepName"
                label="Sales Representative Name"
                value={data.salesRepName || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="salesRepDesignation"
                label="Sales Representative Designation"
                value={data.salesRepDesignation || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default DocumentInfo;