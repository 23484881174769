export const SOW_TYPES = {
    PROJECT: 'project',
    STAFF_AUG: 'staffAug'
  };
  
  export const SOW_TYPE_LABELS = {
    [SOW_TYPES.PROJECT]: 'Project Implementation',
    [SOW_TYPES.STAFF_AUG]: 'Staff Augmentation'
  };
  
  export const SOW_TYPE_DESCRIPTIONS = {
    [SOW_TYPES.PROJECT]: 'Create a detailed SOW for project-based work with milestones, timelines, and comprehensive project scope.',
    [SOW_TYPES.STAFF_AUG]: 'Create a SOW for staff augmentation services with role details, commercial terms, and engagement specifics.'
  };