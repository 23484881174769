import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import RichTextEditor from '../common/RichTextEditor';

function ScopeDefinition({ data, onChange }) {
  const handleAddTable = () => {
    onChange({
      ...data,
      scopeTables: [
        ...data.scopeTables,
        {
          title: `Scope Table ${data.scopeTables.length + 1}`,
          items: [{ area: '', description: '' }]
        }
      ]
    });
  };

  const handleRemoveTable = (tableIndex) => {
    const newTables = data.scopeTables.filter((_, index) => index !== tableIndex);
    onChange({
      ...data,
      scopeTables: newTables
    });
  };

  const handleTableTitleChange = (tableIndex, newTitle) => {
    const newTables = [...data.scopeTables];
    newTables[tableIndex].title = newTitle;
    onChange({
      ...data,
      scopeTables: newTables
    });
  };

  const handleAddItem = (tableIndex) => {
    const newTables = [...data.scopeTables];
    newTables[tableIndex].items.push({ area: '', description: '' });
    onChange({
      ...data,
      scopeTables: newTables
    });
  };

  const handleRemoveItem = (tableIndex, itemIndex) => {
    const newTables = [...data.scopeTables];
    newTables[tableIndex].items = newTables[tableIndex].items.filter(
      (_, index) => index !== itemIndex
    );
    onChange({
      ...data,
      scopeTables: newTables
    });
  };

  const handleItemChange = (tableIndex, itemIndex, field, value) => {
    const newTables = [...data.scopeTables];
    newTables[tableIndex].items[itemIndex][field] = value;
    onChange({
      ...data,
      scopeTables: newTables
    });
  };

  const handleAddAssumption = () => {
    onChange({
      ...data,
      assumptions: [...(data.assumptions || []), '']
    });
  };

  const handleRemoveAssumption = (index) => {
    const newAssumptions = data.assumptions.filter((_, i) => i !== index);
    onChange({
      ...data,
      assumptions: newAssumptions
    });
  };

  const handleAssumptionChange = (index, value) => {
    const newAssumptions = [...data.assumptions];
    newAssumptions[index] = value;
    onChange({
      ...data,
      assumptions: newAssumptions
    });
  };

  const handleAddApproach = () => {
    onChange({
      ...data,
      executionApproach: [...(data.executionApproach || []), '']
    });
  };

  const handleRemoveApproach = (index) => {
    const newApproach = data.executionApproach.filter((_, i) => i !== index);
    onChange({
      ...data,
      executionApproach: newApproach
    });
  };

  const handleApproachChange = (index, value) => {
    const newApproach = [...data.executionApproach];
    newApproach[index] = value;
    onChange({
      ...data,
      executionApproach: newApproach
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Scope Definition
      </Typography>

      {/* Scope Tables */}
      <Box mb={4}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Scope Items</Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddTable}
            variant="outlined"
          >
            Add Table
          </Button>
        </Box>

        {data.scopeTables.map((table, tableIndex) => (
          <Card key={tableIndex} variant="outlined" sx={{ mb: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TextField
                  value={table.title}
                  onChange={(e) => handleTableTitleChange(tableIndex, e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ width: '50%' }}
                />
                {data.scopeTables.length > 1 && (
                  <IconButton
                    onClick={() => handleRemoveTable(tableIndex)}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>

              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '30%' }}>Area</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell style={{ width: '50px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table.items.map((item, itemIndex) => (
                      <TableRow key={itemIndex}>
                        <TableCell>
                          <TextField
                            fullWidth
                            size="small"
                            value={item.area}
                            onChange={(e) => handleItemChange(tableIndex, itemIndex, 'area', e.target.value)}
                          />
                        </TableCell>
                        <TableCell>
                          <RichTextEditor
                            value={item.description}
                            onChange={(value) => handleItemChange(tableIndex, itemIndex, 'description', value)}
                            minHeight="100px"
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleRemoveItem(tableIndex, itemIndex)}
                            disabled={table.items.length === 1}
                            color="error"
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => handleAddItem(tableIndex)}
                  size="small"
                >
                  Add Item
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Assumptions */}
      <Box mb={4}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Assumptions</Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddAssumption}
            variant="outlined"
          >
            Add Assumption
          </Button>
        </Box>

        {data.assumptions.map((assumption, index) => (
          <Card key={index} variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <RichTextEditor
                    value={assumption}
                    onChange={(value) => handleAssumptionChange(index, value)}
                    minHeight="100px"
                  />
                </Box>
                <IconButton
                  onClick={() => handleRemoveAssumption(index)}
                  disabled={data.assumptions.length === 1}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Execution Approach */}
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Execution Approach</Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddApproach}
            variant="outlined"
          >
            Add Approach
          </Button>
        </Box>

        {data.executionApproach.map((approach, index) => (
          <Card key={index} variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <RichTextEditor
                    value={approach}
                    onChange={(value) => handleApproachChange(index, value)}
                    minHeight="100px"
                  />
                </Box>
                <IconButton
                  onClick={() => handleRemoveApproach(index)}
                  disabled={data.executionApproach.length === 1}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

export default ScopeDefinition;