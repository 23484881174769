import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import RichTextEditor from '../common/RichTextEditor';

function TermsAndConditions({ data, onChange }) {
  const handleAddSection = () => {
    onChange({
      ...data,
      sections: [
        ...(data.sections || []),
        {
          title: '',
          points: ['']
        }
      ]
    });
  };

  const handleRemoveSection = (sectionIndex) => {
    const newSections = data.sections.filter((_, index) => index !== sectionIndex);
    onChange({
      ...data,
      sections: newSections
    });
  };

  const handleSectionTitleChange = (sectionIndex, title) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].title = title;
    onChange({
      ...data,
      sections: newSections
    });
  };

  const handleAddPoint = (sectionIndex) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].points.push('');
    onChange({
      ...data,
      sections: newSections
    });
  };

  const handleRemovePoint = (sectionIndex, pointIndex) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].points = newSections[sectionIndex].points.filter(
      (_, index) => index !== pointIndex
    );
    onChange({
      ...data,
      sections: newSections
    });
  };

  const handlePointChange = (sectionIndex, pointIndex, value) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].points[pointIndex] = value;
    onChange({
      ...data,
      sections: newSections
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6" color="primary">
          Terms and Conditions
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddSection}
          variant="outlined"
        >
          Add Section
        </Button>
      </Box>

      {data.sections.map((section, sectionIndex) => (
        <Card key={sectionIndex} variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
              <TextField
                fullWidth
                label="Section Title"
                value={section.title}
                onChange={(e) => handleSectionTitleChange(sectionIndex, e.target.value)}
                variant="outlined"
                sx={{ mr: 2 }}
              />
              {data.sections.length > 1 && (
                <IconButton
                  onClick={() => handleRemoveSection(sectionIndex)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>

            <Divider sx={{ my: 2 }} />

            <List>
              {section.points.map((point, pointIndex) => (
                <ListItem
                  key={pointIndex}
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    mb: 1
                  }}
                >
                  <ListItemText
                    primary={
                      <RichTextEditor
                        value={point}
                        onChange={(value) => handlePointChange(sectionIndex, pointIndex, value)}
                        minHeight="80px"
                      />
                    }
                  />
                  <IconButton
                    onClick={() => handleRemovePoint(sectionIndex, pointIndex)}
                    disabled={section.points.length === 1}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>

            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddPoint(sectionIndex)}
              variant="outlined"
              size="small"
              sx={{ mt: 2 }}
            >
              Add Point
            </Button>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}

export default TermsAndConditions;