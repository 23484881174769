import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import RichTextEditor from '../common/RichTextEditor';

function CommercialsSection({ data, onChange }) {
  const handleChange = (field, value) => {
    onChange({
      ...data,
      [field]: value
    });
  };

  const handleAddPaymentTerm = () => {
    onChange({
      ...data,
      paymentTerms: [...(data.paymentTerms || []), '']
    });
  };

  const handleRemovePaymentTerm = (index) => {
    const newTerms = data.paymentTerms.filter((_, i) => i !== index);
    onChange({
      ...data,
      paymentTerms: newTerms
    });
  };

  const handlePaymentTermChange = (index, value) => {
    const newTerms = [...data.paymentTerms];
    newTerms[index] = value;
    onChange({
      ...data,
      paymentTerms: newTerms
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Commercials
      </Typography>

      {/* Role and Rate */}
      <Card variant="outlined" sx={{ mb: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Role Name"
                value={data.roleName || ''}
                onChange={(e) => handleChange('roleName', e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Rate"
                value={data.rate || ''}
                onChange={(e) => handleChange('rate', e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Payment Terms */}
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">
            Payment Terms and Schedule
          </Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddPaymentTerm}
            variant="outlined"
          >
            Add Payment Term
          </Button>
        </Box>

        <Card variant="outlined">
          <CardContent>
            <List>
              {data.paymentTerms.map((term, index) => (
                <ListItem
                  key={index}
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    mb: 1
                  }}
                >
                  <ListItemText
                    primary={
                      <RichTextEditor
                        value={term}
                        onChange={(value) => handlePaymentTermChange(index, value)}
                        minHeight="80px"
                      />
                    }
                  />
                  <IconButton
                    onClick={() => handleRemovePaymentTerm(index)}
                    disabled={data.paymentTerms.length === 1}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default CommercialsSection;
