import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  TextField,
  Grid,
  CardMedia,
  Alert
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Upload as UploadIcon } from '@mui/icons-material';
import RichTextEditor from '../common/RichTextEditor';

const compressImage = async (file, maxWidth = 800) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
          height = (maxWidth * height) / width;
          width = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0, width, height);
        
        resolve(canvas.toDataURL('image/jpeg', 0.7));
      };
    };
  });
};

function ImageSections({ data, onChange }) {
  const handleAddSection = () => {
    onChange({
      ...data,
      sections: [
        ...(data.sections || []),
        {
          title: '',
          images: []
        }
      ]
    });
  };

  const handleRemoveSection = (sectionIndex) => {
    const newSections = data.sections.filter((_, index) => index !== sectionIndex);
    onChange({
      ...data,
      sections: newSections
    });
  };

  const handleSectionTitleChange = (sectionIndex, value) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].title = value;
    onChange({
      ...data,
      sections: newSections
    });
  };

  const handleImageUpload = async (sectionIndex, files) => {
    try {
      const newSections = [...data.sections];
      const processedImages = await Promise.all(
        Array.from(files).map(async (file) => {
          if (file.size > 5 * 1024 * 1024) {
            throw new Error(`File ${file.name} exceeds 5MB limit`);
          }
          const compressedImage = await compressImage(file);
          return {
            data: compressedImage,
            caption: ''
          };
        })
      );
      
      newSections[sectionIndex].images.push(...processedImages);
      onChange({
        ...data,
        sections: newSections
      });
    } catch (error) {
      console.error('Error processing images:', error);
      alert(error.message);
    }
  };

  const handleRemoveImage = (sectionIndex, imageIndex) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].images = newSections[sectionIndex].images.filter(
      (_, index) => index !== imageIndex
    );
    onChange({
      ...data,
      sections: newSections
    });
  };

  const handleImageCaptionChange = (sectionIndex, imageIndex, value) => {
    const newSections = [...data.sections];
    newSections[sectionIndex].images[imageIndex].caption = value;
    onChange({
      ...data,
      sections: newSections
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6" color="primary">
          Image Sections
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddSection}
          variant="outlined"
        >
          Add Section
        </Button>
      </Box>

      {data.sections.map((section, sectionIndex) => (
        <Card key={sectionIndex} variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <TextField
                fullWidth
                label="Section Title"
                value={section.title}
                onChange={(e) => handleSectionTitleChange(sectionIndex, e.target.value)}
                variant="outlined"
                sx={{ mr: 2 }}
              />
              {data.sections.length > 1 && (
                <IconButton
                  onClick={() => handleRemoveSection(sectionIndex)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>

            <Box sx={{ mb: 3 }}>
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadIcon />}
              >
                Upload Images
                <input
                  type="file"
                  hidden
                  multiple
                  accept="image/*"
                  onChange={(e) => handleImageUpload(sectionIndex, e.target.files)}
                />
              </Button>
              <Typography variant="caption" color="textSecondary" sx={{ ml: 2 }}>
                Max file size: 5MB per image
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {section.images.map((image, imageIndex) => (
                <Grid item xs={12} sm={6} md={4} key={imageIndex}>
                  <Card variant="outlined">
                    <CardMedia
                      component="img"
                      height="200"
                      image={image.data}
                      alt={`Image ${imageIndex + 1}`}
                      sx={{ objectFit: 'contain' }}
                    />
                    <CardContent>
                      <TextField
                        fullWidth
                        size="small"
                        label="Caption"
                        value={image.caption}
                        onChange={(e) => handleImageCaptionChange(sectionIndex, imageIndex, e.target.value)}
                        sx={{ mb: 1 }}
                      />
                      <IconButton
                        onClick={() => handleRemoveImage(sectionIndex, imageIndex)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {section.images.length === 0 && (
              <Alert severity="info" sx={{ mt: 2 }}>
                No images uploaded yet. Click 'Upload Images' to add images to this section.
              </Alert>
            )}
          </CardContent>
        </Card>
      ))}

      {data.sections.length === 0 && (
        <Alert severity="info">
          No image sections created yet. Click 'Add Section' to create a new section.
        </Alert>
      )}
    </Box>
  );
}

export default ImageSections;