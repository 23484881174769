import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
  IconButton,
  StepButton
} from '@mui/material';
import { 
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  Save as SaveIcon,
  Warning as WarningIcon 
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { generateSOW } from '../utils/pdfGenerator';
import { SOW_TYPES } from '../constants/sowTypes';
import { useAuth } from '../context/AuthContext';

// Import step components
import DocumentInfo from './steps/DocumentInfo';
import ClientInfo from './steps/ClientInfo';
import ProjectDetails from './steps/ProjectDetails';
import ScopeDefinition from './steps/ScopeDefinition';
import ImageSections from './steps/ImageSections';
import TimelineMilestones from './steps/TimelineMilestones';
import BudgetInfo from './steps/BudgetInfo';
import CommercialsSection from './steps/CommercialsSection';
import TermsAndConditions from './steps/TermsAndConditions';
import SOWTypeModal from './SOWTypeModal';

// Initial form state based on SOW type
const getInitialState = (type) => ({
  documentInfo: {
    preparedBy: '',
    version: '1.0',
    salesRepName: '',
    salesRepDesignation: ''
  },
  clientInfo: {
    clientName: '',
    contactPerson: '',
    email: '',
    phone: '',
    address: '',
    logo: null
  },
  projectDetails: {
    title: '',
    description: ''
  },
  scope: {
    scopeTables: [{
      title: 'Scope Items',
      items: [{ area: '', description: '' }]
    }],
    assumptions: [''],
    executionApproach: ['']
  },
  imageSections: {
    sections: [{
      title: '',
      images: []
    }]
  },
  ...(type === SOW_TYPES.PROJECT ? {
    timeline: {
      milestones: [{
        title: '',
        weeks: '',
        deliverables: ''
      }]
    },
    budget: {
      totalAmount: '',
      currency: 'INR',
      paymentSchedule: [{
        milestone: '',
        percentage: '',
        amount: ''
      }]
    }
  } : {
    commercials: {
      roleName: '',
      rate: '',
      paymentTerms: ['']
    },
    termsAndConditions: {
      sections: [{
        title: '',
        points: ['']
      }]
    }
  })
});

const getSteps = (type) => (
  type === SOW_TYPES.PROJECT
    ? ['Document Info', 'Client Information', 'Project Details', 'Scope', 'Images', 'Timeline', 'Budget']
    : ['Document Info', 'Client Information', 'Project Details', 'Scope', 'Images', 'Commercials', 'Terms & Conditions']
);

function SOWGenerator() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  
  const [sowType, setSOWType] = useState(null);
  const [showTypeModal, setShowTypeModal] = useState(!id);
  const [formData, setFormData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [clearDialogOpen, setClearDialogOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [validationError, setValidationError] = useState(null);
  const [unsavedChangesDialog, setUnsavedChangesDialog] = useState(null);
  const [typeChangeWarning, setTypeChangeWarning] = useState(null);
  const [showHelp, setShowHelp] = useState(false);

  // Fetch existing SOW data if in edit mode
  useEffect(() => {
    if (id) {
      fetchSOW();
    }
  }, [id]);

  // Auto-save functionality with debounce
  useEffect(() => {
    let saveTimeout;
    
    if (hasUnsavedChanges && !isSaving && !isGenerating) {
      saveTimeout = setTimeout(() => {
        handleSave(true);
      }, 30000); // Auto-save after 30 seconds of no changes
    }

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, [hasUnsavedChanges, isSaving, isGenerating, formData]);

  // Prevent accidental navigation
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  const fetchSOW = async () => {
    try {
      setIsSaving(true);
      const { data, error } = await supabase
        .from('sows')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      if (data) {
        setSOWType(data.sow_type);
        setFormData(JSON.parse(data.form_data));
        setNotification({
          open: true,
          message: 'SOW loaded successfully',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error fetching SOW:', error);
      setNotification({
        open: true,
        message: 'Error loading SOW. Please try again.',
        severity: 'error'
      });
      navigate('/');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSOWTypeSelect = (type) => {
    setSOWType(type);
    setFormData(getInitialState(type));
    setShowTypeModal(false);
  };

  // Validation rules for each step
const stepValidation = {
  documentInfo: (data) => {
    if (!data.preparedBy) return 'Prepared By is required';
    if (!data.version) return 'Version is required';
    return null;
  },
  clientInfo: (data) => {
    if (!data.clientName) return 'Client Name is required';
    if (!data.email && !data.phone) return 'Either Email or Phone is required';
    return null;
  },
  projectDetails: (data) => {
    if (!data.title) return 'Project Title is required';
    return null;
  },
  scope: (data) => {
    if (!data.scopeTables?.[0]?.items?.[0]?.area || 
        !data.scopeTables?.[0]?.items?.[0]?.description) {
      return 'At least one scope item is required';
    }
    return null;
  },
  timeline: (data) => {
    if (!data.milestones?.[0]?.title || !data.milestones?.[0]?.weeks) {
      return 'At least one milestone is required';
    }
    return null;
  },
  budget: (data) => {
    if (!data.totalAmount) return 'Total Amount is required';
    const totalPercentage = data.paymentSchedule?.reduce(
      (sum, item) => sum + (parseFloat(item.percentage) || 0),
      0
    );
    if (totalPercentage !== 100) return 'Payment schedule must total 100%';
    return null;
  },
  commercials: (data) => {
    if (!data.roleName) return 'Role Name is required';
    if (!data.rate) return 'Rate is required';
    return null;
  },
  termsAndConditions: (data) => {
    if (!data.sections?.[0]?.points?.[0]) {
      return 'At least one term is required';
    }
    return null;
  }
};

// Map step indices to validation functions
const getStepValidation = (type, stepIndex) => {
  const validationMap = {
    [SOW_TYPES.PROJECT]: {
      0: 'documentInfo',
      1: 'clientInfo',
      2: 'projectDetails',
      3: 'scope',
      4: null, // Images are optional
      5: 'timeline',
      6: 'budget'
    },
    [SOW_TYPES.STAFF_AUG]: {
      0: 'documentInfo',
      1: 'clientInfo',
      2: 'projectDetails',
      3: 'scope',
      4: null, // Images are optional
      5: 'commercials',
      6: 'termsAndConditions'
    }
  };

  const validationKey = validationMap[type]?.[stepIndex];
  return validationKey ? stepValidation[validationKey] : null;
};

  const handleSave = async (silent = false) => {
    if (isSaving) return false; // Prevent concurrent saves
    
    setIsSaving(true);
    try {
      const sowData = {
        form_data: JSON.stringify(formData),
        sow_type: sowType,
        client_name: formData.clientInfo.clientName || 'Untitled',
        version: formData.documentInfo.version || '1.0',
        prepared_by: formData.documentInfo.preparedBy || '',
        created_by: user.id,
        updated_at: new Date().toISOString() // Add timestamp
      };

      if (id) {
        // First check if the record still exists and hasn't been modified
        const { data: existing } = await supabase
          .from('sows')
          .select('updated_at')
          .eq('id', id)
          .single();

        if (!existing) {
          throw new Error('SOW no longer exists');
        }

        const { error } = await supabase
          .from('sows')
          .update(sowData)
          .match({ id })
          .select()
          .single();

        if (error) throw error;
      } else {
        const { data, error } = await supabase
          .from('sows')
          .insert([{
            ...sowData,
            created_at: new Date().toISOString()
          }])
          .select('id')
          .single();

        if (error) throw error;
        
        if (data?.id) {
          window.history.replaceState(null, '', `/edit/${data.id}`);
        }
      }

      setHasUnsavedChanges(false);

      if (!silent) {
        setNotification({
          open: true,
          message: 'SOW saved successfully!',
          severity: 'success'
        });
      }

      return true;
    } catch (error) {
      console.error('Error saving SOW:', error);
      setNotification({
        open: true,
        message: `Error saving SOW: ${error.message}`,
        severity: 'error'
      });
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const handleNext = async () => {
    const saved = await handleSave();
    if (saved) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => Math.max(0, prev - 1));
  };

  const handleFormChange = (section, data) => {
    setFormData(prev => ({
      ...prev,
      [section]: { ...prev[section], ...data }
    }));
    setHasUnsavedChanges(true);
  };

  const handleGenerateSOW = async () => {
    setIsGenerating(true);
    try {
      const saved = await handleSave(true);
      if (!saved) throw new Error('Failed to save SOW data');

      await generateSOW(formData, sowType);
      
      setNotification({
        open: true,
        message: 'SOW generated and saved successfully!',
        severity: 'success'
      });

      setTimeout(() => navigate('/'), 1500);
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error generating SOW. Please try again.',
        severity: 'error'
      });
      console.error('SOW Generation Error:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleClearForm = () => {
    if (hasUnsavedChanges) {
      setClearDialogOpen(true);
    } else {
      confirmClearForm();
    }
  };

  const confirmClearForm = () => {
    setFormData(getInitialState(sowType));
    setCurrentStep(0);
    setClearDialogOpen(false);
    setHasUnsavedChanges(false);
    setNotification({
      open: true,
      message: 'Form cleared successfully',
      severity: 'info'
    });
  };

  const renderStep = () => {
    if (!formData) return null;

    switch (currentStep) {
      case 0:
        return (
          <DocumentInfo
            data={formData.documentInfo}
            onChange={(data) => handleFormChange('documentInfo', data)}
          />
        );
      case 1:
        return (
          <ClientInfo
            data={formData.clientInfo}
            onChange={(data) => handleFormChange('clientInfo', data)}
          />
        );
      case 2:
        return (
          <ProjectDetails
            data={formData.projectDetails}
            onChange={(data) => handleFormChange('projectDetails', data)}
          />
        );
      case 3:
        return (
          <ScopeDefinition
            data={formData.scope}
            onChange={(data) => handleFormChange('scope', data)}
          />
        );
      case 4:
        return (
          <ImageSections
            data={formData.imageSections}
            onChange={(data) => handleFormChange('imageSections', data)}
          />
        );
      case 5:
        return sowType === SOW_TYPES.PROJECT ? (
          <TimelineMilestones
            data={formData.timeline}
            onChange={(data) => handleFormChange('timeline', data)}
          />
        ) : (
          <CommercialsSection
            data={formData.commercials}
            onChange={(data) => handleFormChange('commercials', data)}
          />
        );
      case 6:
        return sowType === SOW_TYPES.PROJECT ? (
          <BudgetInfo
            data={formData.budget}
            onChange={(data) => handleFormChange('budget', data)}
          />
        ) : (
          <TermsAndConditions
            data={formData.termsAndConditions}
            onChange={(data) => handleFormChange('termsAndConditions', data)}
          />
        );
      default:
        return null;
    }
  };

  if (!sowType) {
    return (
      <SOWTypeModal
        open={showTypeModal}
        onClose={() => navigate('/')}
        onSelect={handleSOWTypeSelect}
      />
    );
  }

  const steps = getSteps(sowType);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar 
        position="fixed" 
        sx={{ 
          backgroundColor: 'background.paper',
          boxShadow: 1
        }}
      >
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => {
                if (hasUnsavedChanges) {
                  handleSave(true).then(() => navigate('/'));
                } else {
                  navigate('/');
                }
              }}
              sx={{ mr: 2, color: 'primary.main' }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography 
              variant="h6" 
              sx={{ 
                flexGrow: 1,
                color: 'primary.main',
                fontWeight: 600
              }}
            >
              {id ? 'Edit SOW' : 'Create New SOW'}
              {hasUnsavedChanges && (
                <Typography 
                  component="span" 
                  sx={{ 
                    ml: 2,
                    color: 'warning.main',
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: '0.875rem'
                  }}
                >
                  <WarningIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
                  Unsaved Changes
                </Typography>
              )}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={() => handleSave()}
              disabled={isGenerating || isSaving || !hasUnsavedChanges}
              sx={{ mr: 2 }}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleClearForm}
              disabled={isGenerating || isSaving}
            >
              Clear Form
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar /> {/* Spacer for fixed AppBar */}

      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper 
          elevation={0} 
          sx={{ 
            p: 4,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Statement of Work Generator
          </Typography>

          <Stepper 
            activeStep={currentStep} 
            alternativeLabel 
            nonLinear
            sx={{ my: 4 }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={() => setCurrentStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ mt: 4, mb: 4 }}>
            {renderStep()}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={currentStep === 0 || isGenerating || isSaving}
            >
              Back
            </Button>
            
            {currentStep === steps.length - 1 ? (
              <Button
                variant="contained"
                onClick={handleGenerateSOW}
                disabled={isGenerating || isSaving}
              >
                {isGenerating ? 'Generating...' : 'Generate SOW'}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={isGenerating || isSaving}
              >
                {isSaving ? 'Saving...' : 'Next'}
              </Button>
            )}
          </Box>
        </Paper>
      </Container>

      {/* Clear Form Dialog */}
      <Dialog
        open={clearDialogOpen}
        onClose={() => setClearDialogOpen(false)}
      >
        <DialogTitle>Clear Form</DialogTitle>
        <DialogContent>
          Are you sure you want to clear all form data? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setClearDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmClearForm} color="error">
            Clear Form
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading Overlay */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isGenerating || isSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setNotification(prev => ({ ...prev, open: false }))}
            severity={notification.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
  
        {/* Step Validation Dialog */}
        <Dialog
          open={!!validationError}
          onClose={() => setValidationError(null)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ color: 'error.main' }}>
            Validation Error
          </DialogTitle>
          <DialogContent>
            <Typography>
              {validationError}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setValidationError(null)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
  
        {/* Unsaved Changes Dialog */}
        <Dialog
          open={!!unsavedChangesDialog}
          onClose={() => setUnsavedChangesDialog(null)}
        >
          <DialogTitle>
            Unsaved Changes
          </DialogTitle>
          <DialogContent>
            <Typography>
              You have unsaved changes. Would you like to save them before proceeding?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => {
                setUnsavedChangesDialog(null);
                unsavedChangesDialog.discard();
              }}
              color="error"
            >
              Discard Changes
            </Button>
            <Button
              onClick={() => {
                handleSave().then(saved => {
                  if (saved) {
                    unsavedChangesDialog.proceed();
                  }
                });
                setUnsavedChangesDialog(null);
              }}
              variant="contained"
              color="primary"
            >
              Save & Continue
            </Button>
          </DialogActions>
        </Dialog>
  
        {/* Type Change Warning Dialog */}
        <Dialog
          open={!!typeChangeWarning}
          onClose={() => setTypeChangeWarning(null)}
        >
          <DialogTitle>
            Change SOW Type
          </DialogTitle>
          <DialogContent>
            <Typography>
              Changing the SOW type will reset some sections of your form. Are you sure you want to proceed?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTypeChangeWarning(null)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                const newType = typeChangeWarning;
                setSOWType(newType);
                setFormData(prev => ({
                  ...getInitialState(newType),
                  documentInfo: prev.documentInfo,
                  clientInfo: prev.clientInfo,
                  projectDetails: prev.projectDetails,
                  scope: prev.scope,
                  imageSections: prev.imageSections
                }));
                setTypeChangeWarning(null);
                setHasUnsavedChanges(true);
              }}
              color="error"
            >
              Change Type
            </Button>
          </DialogActions>
        </Dialog>
  
        {/* Help Dialog */}
        <Dialog
          open={showHelp}
          onClose={() => setShowHelp(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            SOW Generator Help
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Getting Started
            </Typography>
            <Typography paragraph>
              The SOW Generator helps you create professional Statements of Work. Follow these steps:
            </Typography>
            <Typography component="div">
              <ol>
                <li>Choose between Project Implementation and Staff Augmentation SOW types</li>
                <li>Fill in each section using the step-by-step form</li>
                <li>Use the rich text editor for detailed descriptions</li>
                <li>Add images and format text as needed</li>
                <li>Review and generate your SOW</li>
              </ol>
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Features
            </Typography>
            <Typography component="div">
              <ul>
                <li>Auto-save every 30 seconds</li>
                <li>Rich text editing with formatting</li>
                <li>Image uploads with compression</li>
                <li>PDF generation with proper formatting</li>
                <li>Version control and history</li>
              </ul>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowHelp(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
  
  // Add prop types validation
  SOWGenerator.propTypes = {
    // Add prop types if needed
  };
  
  export default SOWGenerator;
