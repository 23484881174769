import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  InputAdornment
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const CURRENCIES = [
  { value: 'INR', label: 'INR (₹)' },
  { value: 'USD', label: 'USD ($)' },
  { value: 'EUR', label: 'EUR (€)' },
  { value: 'GBP', label: 'GBP (£)' }
];

function BudgetInfo({ data, onChange }) {
  const handleCurrencyChange = (event) => {
    onChange({
      ...data,
      currency: event.target.value
    });
  };

  const handleTotalAmountChange = (event) => {
    onChange({
      ...data,
      totalAmount: event.target.value
    });
  };

  const handleAddPayment = () => {
    onChange({
      ...data,
      paymentSchedule: [
        ...(data.paymentSchedule || []),
        {
          milestone: '',
          percentage: '',
          amount: ''
        }
      ]
    });
  };

  const handleRemovePayment = (index) => {
    const newSchedule = data.paymentSchedule.filter((_, i) => i !== index);
    onChange({
      ...data,
      paymentSchedule: newSchedule
    });
  };

  const handlePaymentChange = (index, field, value) => {
    const newSchedule = [...data.paymentSchedule];
    newSchedule[index] = {
      ...newSchedule[index],
      [field]: value
    };

    // Calculate amount if percentage changes
    if (field === 'percentage' && data.totalAmount) {
      const percentage = parseFloat(value) || 0;
      const totalAmount = parseFloat(data.totalAmount) || 0;
      newSchedule[index].amount = ((percentage * totalAmount) / 100).toFixed(2);
    }

    onChange({
      ...data,
      paymentSchedule: newSchedule
    });
  };

  // Calculate total percentage
  const totalPercentage = data.paymentSchedule.reduce((sum, item) => {
    return sum + (parseFloat(item.percentage) || 0);
  }, 0);

  // Update amounts when total amount changes
  useEffect(() => {
    if (data.totalAmount && data.paymentSchedule) {
      const newSchedule = data.paymentSchedule.map(item => ({
        ...item,
        amount: item.percentage 
          ? ((parseFloat(item.percentage) * parseFloat(data.totalAmount)) / 100).toFixed(2)
          : ''
      }));
      
      onChange({
        ...data,
        paymentSchedule: newSchedule
      });
    }
  }, [data.totalAmount]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Budget Information
      </Typography>

      <Card variant="outlined" sx={{ mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              select
              label="Currency"
              value={data.currency}
              onChange={handleCurrencyChange}
              sx={{ width: 150 }}
            >
              {CURRENCIES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            
            <TextField
              fullWidth
              label="Total Amount"
              type="number"
              value={data.totalAmount}
              onChange={handleTotalAmountChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {CURRENCIES.find(c => c.value === data.currency)?.label.split(' ')[1]}
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </CardContent>
      </Card>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Payment Schedule
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddPayment}
          variant="outlined"
        >
          Add Payment
        </Button>
      </Box>

      <Card variant="outlined">
        <CardContent>
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Milestone</TableCell>
                  <TableCell width={150}>Percentage (%)</TableCell>
                  <TableCell width={200}>Amount</TableCell>
                  <TableCell width={50} />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.paymentSchedule.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        value={payment.milestone}
                        onChange={(e) => handlePaymentChange(index, 'milestone', e.target.value)}
                        placeholder="Enter milestone"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        value={payment.percentage}
                        onChange={(e) => handlePaymentChange(index, 'percentage', e.target.value)}
                        placeholder="Enter %"
                        inputProps={{ min: 0, max: 100 }}
                        error={totalPercentage > 100}
                        helperText={totalPercentage > 100 ? 'Total exceeds 100%' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        value={payment.amount}
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              {CURRENCIES.find(c => c.value === data.currency)?.label.split(' ')[1]}
                            </InputAdornment>
                          )
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleRemovePayment(index)}
                        disabled={data.paymentSchedule.length === 1}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">Total</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography 
                      variant="subtitle2" 
                      color={totalPercentage !== 100 ? 'error' : 'inherit'}
                    >
                      {totalPercentage}%
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {CURRENCIES.find(c => c.value === data.currency)?.label.split(' ')[1]}
                      {data.totalAmount || '0'}
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {totalPercentage !== 100 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Total percentage must equal 100%. Current total: {totalPercentage}%
            </Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default BudgetInfo;
