import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { SOW_TYPES } from '../constants/sowTypes';
import { htmlToText } from 'html-to-text';

// Helper functions
const stripHtmlTags = (html) => {
  if (!html) return '';
  return htmlToText(html, {
    wordwrap: 130,
    selectors: [
      { selector: 'img', format: 'skip' },
      { selector: 'a', options: { ignoreHref: true } }
    ],
    formatters: {
      bulletList: (elem, walk, builder) => {
        elem.children.forEach((item, i) => {
          builder.addInline('• ');
          walk(item.children);
          if (i < elem.children.length - 1) {
            builder.addLineBreak();
          }
        });
      },
      numberList: (elem, walk, builder) => {
        elem.children.forEach((item, i) => {
          builder.addInline(`${i + 1}. `);
          walk(item.children);
          if (i < elem.children.length - 1) {
            builder.addLineBreak();
          }
        });
      }
    }
  });
};

const addHeaderImage = (doc, pageWidth) => {
  return new Promise((resolve, reject) => {
    const headerImg = new Image();
    headerImg.crossOrigin = "Anonymous";
    headerImg.src = "https://res.cloudinary.com/dhtfyxzcp/image/upload/v1730826473/Premium_Salesforce_Consulting_Services_pwgwue.png";
    headerImg.onload = () => {
      try {
        const imgWidth = pageWidth;
        const imgHeight = (headerImg.height * pageWidth) / headerImg.width;
        doc.addImage(headerImg, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');
        resolve(imgHeight);
      } catch (error) {
        console.error('Error adding header image:', error);
        resolve(0);
      }
    };
    headerImg.onerror = () => resolve(0);
  });
};

const addClientLogo = async (doc, logoData, pageWidth, yPos) => {
  if (!logoData) return yPos;

  try {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        try {
          const maxWidth = 120;
          const maxHeight = 60;
          
          let imgWidth = img.width;
          let imgHeight = img.height;
          
          const aspectRatio = imgWidth / imgHeight;
          
          if (imgWidth > maxWidth) {
            imgWidth = maxWidth;
            imgHeight = imgWidth / aspectRatio;
          }
          if (imgHeight > maxHeight) {
            imgHeight = maxHeight;
            imgWidth = imgHeight * aspectRatio;
          }
          
          const xPos = (pageWidth - imgWidth) / 2;
          
          doc.addImage(logoData, 'JPEG', xPos, yPos, imgWidth, imgHeight, undefined, 'FAST');
          resolve(yPos + imgHeight + 20);
        } catch (error) {
          console.error('Error adding client logo:', error);
          resolve(yPos);
        }
      };
      img.onerror = () => resolve(yPos);
      img.src = logoData;
    });
  } catch (error) {
    console.error('Error processing client logo:', error);
    return yPos;
  }
};

const addFooterWithLogo = (doc, pageWidth, pageHeight, margin, colors) => {
  return new Promise((resolve) => {
    const footerLogo = new Image();
    footerLogo.crossOrigin = "Anonymous";
    footerLogo.src = "https://res.cloudinary.com/dhtfyxzcp/image/upload/v1730826614/Logo_ss1zor.png";
    footerLogo.onload = () => {
      try {
        const logoWidth = 52.5; // Increased by 50%
        const logoHeight = (footerLogo.height * logoWidth) / footerLogo.width;
        
        doc.setFillColor(245, 245, 250);
        doc.rect(0, pageHeight - 50, pageWidth, 50, 'F');
        
        // Center logo vertically in footer
        const footerCenterY = pageHeight - 25 - (logoHeight / 2);
        doc.addImage(footerLogo, 'JPEG', margin, footerCenterY, logoWidth, logoHeight, undefined, 'FAST');
        
        doc.setFontSize(8);
        doc.setTextColor(...colors.text);
        doc.text('1809, Iconic Corenthum, Sector-62, Noida-201301, India', pageWidth - margin, pageHeight - 30, { align: 'right' });
        doc.text('Phone: +1(609)-297-6394,+91-8920815307 | Email: sales@paramyter.tech', pageWidth - margin, pageHeight - 20, { align: 'right' });
        
        resolve();
      } catch (error) {
        console.error('Error adding footer:', error);
        resolve();
      }
    };
    footerLogo.onerror = () => resolve();
  });
};

const processText = (text, maxWidth, lineHeight) => {
  if (!text) return [];
  return text.split('\n').map(line => ({
    text: line,
    fontSize: 10,
    lineHeight
  }));
};

const addSectionContent = (doc, content, startY, pageWidth, margin, maxWidth) => {
  let currentY = startY;
  const lineHeight = 12;
  const lines = processText(content, maxWidth, lineHeight);
  
  lines.forEach(line => {
    doc.text(line.text, margin, currentY);
    currentY += line.lineHeight;
  });
  
  return currentY;
};

// Main PDF generation function
export const generateSOW = async (formData, sowType = SOW_TYPES.PROJECT) => {
  try {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
      compress: true
    });

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 40;
    const footerHeight = 120;

    const colors = {
      primary: [0, 44, 95],
      secondary: [0, 103, 177],
      accent: [56, 189, 248],
      text: [30, 41, 59],
      textLight: [100, 116, 139]
    };

    const commonTableStyles = {
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 5,
        overflow: 'linebreak',
        cellWidth: 'wrap',
        halign: 'left',
        valign: 'middle',
        minCellHeight: 10,
        textColor: colors.text
      },
      headStyles: {
        fillColor: colors.primary,
        textColor: [255, 255, 255],
        fontStyle: 'bold'
      },
      margin: {
        top: margin,
        right: margin,
        bottom: footerHeight + margin,
        left: margin
      }
    };

    // Add header image
    const headerHeight = await addHeaderImage(doc, pageWidth);
    let yPos = headerHeight + 20;

    // Add client logo if available
    if (formData.clientInfo?.logo) {
      yPos = await addClientLogo(doc, formData.clientInfo.logo, pageWidth, yPos);
    }

    // Add extra space before title sections
    yPos += 40;

    // Title sections
    doc.setFontSize(24);
    doc.setTextColor(...colors.primary);
    doc.text('Statement of Work', pageWidth / 2, yPos, { align: 'center' });
    yPos += 30;

    // Project Title
    doc.setFontSize(18);
    doc.text(formData.projectDetails.title || '', pageWidth / 2, yPos, { align: 'center' });
    yPos += 40;

    // Document Info Table
    doc.autoTable({
      ...commonTableStyles,
      startY: yPos,
      body: [
        ['Version', formData.documentInfo.version || '1.0'],
        ['Prepared By', formData.documentInfo.preparedBy || ''],
        ['Date', new Date().toLocaleDateString()]
      ],
      columnStyles: {
        0: { fontStyle: 'bold', cellWidth: 150 }
      }
    });

    // Client Information
    doc.addPage();
    yPos = margin;

    doc.setFontSize(16);
    doc.text('Client Information', margin, yPos);
    yPos += 20;

    doc.autoTable({
      ...commonTableStyles,
      startY: yPos,
      body: [
        ['Client Name', formData.clientInfo.clientName || ''],
        ['Contact Person', formData.clientInfo.contactPerson || ''],
        ['Email', formData.clientInfo.email || ''],
        ['Phone', formData.clientInfo.phone || ''],
        ['Address', formData.clientInfo.address || '']
      ],
      columnStyles: {
        0: { fontStyle: 'bold', cellWidth: 150 }
      }
    });

    // Project Details
    doc.addPage();
    yPos = margin;

    doc.setFontSize(16);
    doc.text('Project Details', margin, yPos);
    yPos += 20;

    const description = stripHtmlTags(formData.projectDetails.description);
    doc.autoTable({
      ...commonTableStyles,
      startY: yPos,
      body: [['Description', description || '']],
      columnStyles: {
        0: { fontStyle: 'bold', cellWidth: 150 },
        1: { cellWidth: 'auto' }
      }
    });

    // Scope Items
    doc.addPage();
    yPos = margin;

    doc.setFontSize(16);
    doc.text('Project Scope', margin, yPos);
    yPos += 20;

    // Process each scope table
    for (const table of formData.scope.scopeTables) {
      doc.setFontSize(14);
      doc.text(table.title, margin, yPos);
      yPos += 15;

      const tableBody = table.items.map(item => [
        item.area,
        stripHtmlTags(item.description)
      ]);

      doc.autoTable({
        ...commonTableStyles,
        startY: yPos,
        head: [['Area', 'Description']],
        body: tableBody,
        columnStyles: {
          0: { cellWidth: 150 },
          1: { cellWidth: 'auto' }
        }
      });

      yPos = doc.previousAutoTable.finalY + 40;

      // Add new page if needed
      if (yPos > pageHeight - 100) {
        doc.addPage();
        yPos = margin;
      }
    }

    // Assumptions
    if (formData.scope.assumptions?.length > 0) {
      doc.addPage();
      yPos = margin;

      doc.setFontSize(16);
      doc.text('Assumptions', margin, yPos);
      yPos += 20;

      const assumptions = formData.scope.assumptions.map((assumption, index) => [
        `${index + 1}. ${stripHtmlTags(assumption)}`
      ]);

      doc.autoTable({
        ...commonTableStyles,
        startY: yPos,
        body: assumptions,
        columnStyles: {
          0: { cellWidth: 'auto' }
        }
      });
    }

    // Handle different SOW types
    if (sowType === SOW_TYPES.PROJECT) {
      // Timeline
      doc.addPage();
      yPos = margin;

      doc.setFontSize(16);
      doc.text('Timeline & Milestones', margin, yPos);
      yPos += 20;

      const timelineData = formData.timeline.milestones.map(milestone => [
        milestone.title,
        `Week ${milestone.weeks}`,
        stripHtmlTags(milestone.deliverables)
      ]);

      doc.autoTable({
        ...commonTableStyles,
        startY: yPos,
        head: [['Milestone', 'Timeline', 'Deliverables']],
        body: timelineData
      });

      // Budget
      doc.addPage();
      yPos = margin;

      doc.setFontSize(16);
      doc.text('Budget Information', margin, yPos);
      yPos += 20;

      const budgetData = formData.budget.paymentSchedule.map(payment => [
        payment.milestone,
        `${payment.percentage}%`,
        `${formData.budget.currency} ${payment.amount}`
      ]);

      doc.autoTable({
        ...commonTableStyles,
        startY: yPos,
        head: [['Payment Milestone', 'Percentage', 'Amount']],
        body: budgetData,
        foot: [['Total', '100%', `${formData.budget.currency} ${formData.budget.totalAmount}`]],
        columnStyles: {
          1: { halign: 'right' },
          2: { halign: 'right' }
        }
      });
    } else {
      // Commercials for Staff Aug
      doc.addPage();
      yPos = margin;

      doc.setFontSize(16);
      doc.text('Commercial Terms', margin, yPos);
      yPos += 20;

      doc.autoTable({
        ...commonTableStyles,
        startY: yPos,
        body: [
          ['Role Name', formData.commercials.roleName],
          ['Rate', formData.commercials.rate]
        ],
        columnStyles: {
          0: { fontStyle: 'bold', cellWidth: 150 }
        }
      });

      yPos = doc.previousAutoTable.finalY + 20;

      // Payment Terms
      doc.setFontSize(14);
      doc.text('Payment Terms and Schedule', margin, yPos);
      yPos += 20;

      const paymentTerms = formData.commercials.paymentTerms
        .filter(term => term.trim())
        .map((term, index) => [`${index + 1}. ${stripHtmlTags(term)}`]);

      if (paymentTerms.length > 0) {
        doc.autoTable({
          ...commonTableStyles,
          startY: yPos,
          body: paymentTerms,
          columnStyles: {
            0: { cellWidth: 'auto' }
          }
        });
      }

      // Terms and Conditions
      if (formData.termsAndConditions?.sections?.length > 0) {
        doc.addPage();
        yPos = margin;

        doc.setFontSize(16);
        doc.text('Terms and Conditions', margin, yPos);
        yPos += 20;

        for (const section of formData.termsAndConditions.sections) {
          if (section.title) {
            doc.setFontSize(14);
            doc.text(section.title, margin, yPos);
            yPos += 45;  // Double line break after section title
          }

          const points = section.points
            .filter(point => point.trim())
            .map((point, index) => [`${index + 1}. ${stripHtmlTags(point)}`]);

          if (points.length > 0) {
            doc.autoTable({
              ...commonTableStyles,
              startY: yPos,
              body: points,
              columnStyles: {
                0: { cellWidth: 'auto' }
              }
            });
            yPos = doc.previousAutoTable.finalY + 35;
          }

          // Add new page if needed
          if (yPos > pageHeight - 100 && formData.termsAndConditions.sections.indexOf(section) !== formData.termsAndConditions.sections.length - 1) {
            doc.addPage();
            yPos = margin;
          }
        }
      }
    }

    // Image Sections
    if (formData.imageSections?.sections?.length > 0) {
      for (const section of formData.imageSections.sections) {
        if (!section.images?.length) continue;

        doc.addPage();
        yPos = margin;

        doc.setFontSize(16);
        doc.text(section.title || 'Images', margin, yPos);
        yPos += 20;

        for (const image of section.images) {
          try {
            const img = new Image();
            await new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
              img.src = image.data;
            });

            const maxWidth = pageWidth - (2 * margin);
            const maxHeight = 200;
            
            let imgWidth = img.width;
            let imgHeight = img.height;
            
            if (imgWidth > maxWidth) {
              const ratio = maxWidth / imgWidth;
              imgWidth = maxWidth;
              imgHeight = imgHeight * ratio;
            }
            if (imgHeight > maxHeight) {
              const ratio = maxHeight / imgHeight;
              imgHeight = maxHeight;
              imgWidth = imgWidth * ratio;
            }

            // Check if we need a new page
            if (yPos + imgHeight + (image.caption ? 30 : 10) > pageHeight - footerHeight) {
              doc.addPage();
              yPos = margin;
            }

            // Add image
            doc.addImage(
              image.data,
              'JPEG',
              (pageWidth - imgWidth) / 2, // center horizontally
              yPos,
              imgWidth,
              imgHeight,
              undefined,
              'FAST'
            );

            yPos += imgHeight + 10;

            // Add caption if exists
            if (image.caption) {
              doc.setFontSize(10);
              doc.setTextColor(...colors.text);
              
              const captionLines = doc.splitTextToSize(image.caption, maxWidth);
              doc.text(captionLines, (pageWidth - maxWidth) / 2, yPos);
              
              yPos += (captionLines.length * 12) + 20; // Add spacing after caption
            }

          } catch (error) {
            console.error('Error adding image to PDF:', error);
            continue;
          }
        }
      }
    }

    // Authorization
    doc.addPage();
    yPos = margin;

    doc.setFontSize(16);
    doc.text('Authorization', margin, yPos);
    yPos += 20;

    doc.autoTable({
      ...commonTableStyles,
      startY: yPos,
      theme: 'plain',
      body: [
        ['For Paramyter Tech Private Limited', `For ${formData.clientInfo.clientName || ''}`],
        ['\n\n\n_______________________________', '\n\n\n_______________________________'],
        [`Name: ${formData.documentInfo?.salesRepName || ''}`, 'Name:'],
        [`Designation: ${formData.documentInfo?.salesRepDesignation || ''}`, 'Designation:'],
        [`Date: ${new Date().toLocaleDateString()}`, 'Date:']
      ],
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' }
      }
    });

    // Add footers and page numbers to all pages
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      await addFooterWithLogo(doc, pageWidth, pageHeight, margin, colors);
      
      doc.setFontSize(8);
      doc.text(
        `Page ${i} of ${pageCount}`,
        pageWidth - margin,
        pageHeight - 5,
        { align: 'right' }
      );
    }

    // Set PDF properties
    doc.setProperties({
      title: `SOW - ${formData.projectDetails.title || 'Untitled'}`,
      subject: 'Statement of Work',
      creator: 'Paramyter Tech',
      author: formData.documentInfo?.preparedBy || 'Paramyter Tech',
      keywords: 'SOW, Statement of Work',
      compressed: true
    });

    // Save the PDF
    const fileName = `SOW_${(formData.projectDetails.title || 'Untitled').replace(/\s+/g, '_')}.pdf`;
    doc.save(fileName);
    return true;

  } catch (error) {
    console.error('PDF Generation Error:', error);
    throw new Error('Failed to generate PDF');
  }
};
