import React, { createContext, useContext, useState, useEffect } from 'react';

const FormContext = createContext();
const STORAGE_KEY = 'sow_generator_state';

const initialFormState = {
  documentInfo: {
    preparedBy: '',
    version: '1.0',
    salesRepName: '',
    salesRepDesignation: ''
  },
  clientInfo: {
    clientName: '',
    contactPerson: '',
    email: '',
    phone: '',
    address: ''
  },
  projectDetails: {
    title: '',
    description: '',
    startDate: '',
    endDate: ''
  },
  scope: {
    items: [''],
    assumptions: [''],
    deliverables: ['']
  },
  timeline: {
    milestones: [{
      title: '',
      date: '',
      deliverables: ''
    }]
  },
  budget: {
    totalAmount: '',
    currency: 'INR',
    paymentSchedule: [{
      milestone: '',
      percentage: '',
      amount: ''
    }]
  }
};

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem(STORAGE_KEY);
    return savedData ? JSON.parse(savedData) : initialFormState;
  });
  
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  const updateFormData = (section, data) => {
    setFormData(prev => ({
      ...prev,
      [section]: { ...prev[section], ...data }
    }));
  };

  const clearForm = () => {
    setFormData(initialFormState);
    setCurrentStep(0);
    localStorage.removeItem(STORAGE_KEY);
  };

  const nextStep = () => {
    setCurrentStep(prev => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep(prev => Math.max(0, prev - 1));
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
        currentStep,
        nextStep,
        prevStep,
        clearForm,
        setCurrentStep
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};