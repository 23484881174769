import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import RichTextEditor from '../common/RichTextEditor';

function TimelineMilestones({ data, onChange }) {
  const handleAddMilestone = () => {
    onChange({
      ...data,
      milestones: [
        ...(data.milestones || []),
        {
          title: '',
          weeks: '',
          deliverables: ''
        }
      ]
    });
  };

  const handleRemoveMilestone = (index) => {
    const newMilestones = data.milestones.filter((_, i) => i !== index);
    onChange({
      ...data,
      milestones: newMilestones
    });
  };

  const handleMilestoneChange = (index, field, value) => {
    const newMilestones = [...data.milestones];
    newMilestones[index] = {
      ...newMilestones[index],
      [field]: value
    };
    onChange({
      ...data,
      milestones: newMilestones
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6" color="primary">
          Timeline & Milestones
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddMilestone}
          variant="outlined"
        >
          Add Milestone
        </Button>
      </Box>

      <Card variant="outlined">
        <CardContent>
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Milestone</TableCell>
                  <TableCell width={150}>Timeline (Weeks)</TableCell>
                  <TableCell>Deliverables</TableCell>
                  <TableCell width={50} />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.milestones.map((milestone, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        value={milestone.title}
                        onChange={(e) => handleMilestoneChange(index, 'title', e.target.value)}
                        placeholder="Enter milestone title"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        value={milestone.weeks}
                        onChange={(e) => handleMilestoneChange(index, 'weeks', e.target.value)}
                        placeholder="Enter weeks"
                        inputProps={{ min: 0 }}
                      />
                    </TableCell>
                    <TableCell>
                      <RichTextEditor
                        value={milestone.deliverables}
                        onChange={(value) => handleMilestoneChange(index, 'deliverables', value)}
                        minHeight="80px"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleRemoveMilestone(index)}
                        disabled={data.milestones.length === 1}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
}

export default TimelineMilestones;