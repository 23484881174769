import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Card,
  CardActionArea,
  Typography,
  Box
} from '@mui/material';
import {
  Engineering as EngineeringIcon,
  Assignment as AssignmentIcon
} from '@mui/icons-material';
import { SOW_TYPES, SOW_TYPE_LABELS, SOW_TYPE_DESCRIPTIONS } from '../constants/sowTypes';

function SOWTypeModal({ open, onClose, onSelect }) {
  const TypeCard = ({ type, icon: Icon, title, description }) => (
    <Grid item xs={12} sm={6}>
      <Card 
        elevation={0}
        sx={{ 
          border: '1px solid',
          borderColor: 'divider',
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: theme => theme.shadows[4]
          }
        }}
      >
        <CardActionArea 
          onClick={() => onSelect(type)}
          sx={{ p: 3 }}
        >
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Icon sx={{ fontSize: 48, color: 'primary.main' }} />
          </Box>
          <Typography variant="h6" align="center" gutterBottom>
            {title}
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary" 
            align="center"
            sx={{ minHeight: 60 }}
          >
            {description}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: 2
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          textAlign: 'center', 
          pb: 1,
          pt: 3
        }}
      >
        <Typography variant="h5" component="h2" fontWeight="500">
          Select SOW Type
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <Typography 
          variant="body1" 
          color="text.secondary" 
          align="center" 
          sx={{ mb: 4 }}
        >
          Choose the type of Statement of Work you want to create
        </Typography>

        <Grid container spacing={3} justifyContent="center">
          <TypeCard
            type={SOW_TYPES.PROJECT}
            icon={AssignmentIcon}
            title={SOW_TYPE_LABELS[SOW_TYPES.PROJECT]}
            description={SOW_TYPE_DESCRIPTIONS[SOW_TYPES.PROJECT]}
          />
          <TypeCard
            type={SOW_TYPES.STAFF_AUG}
            icon={EngineeringIcon}
            title={SOW_TYPE_LABELS[SOW_TYPES.STAFF_AUG]}
            description={SOW_TYPE_DESCRIPTIONS[SOW_TYPES.STAFF_AUG]}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default SOWTypeModal;